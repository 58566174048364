import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoData } from '../data/seo';
import { defaultLangKey } from '../data/languages';
import { graphql } from 'gatsby';
import Image, { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import tw from 'twin.macro';
import LogoLg from '../components/logo-lg';
import ContentWidth from '../components/contentWidth';
import { Link } from '../utils/link';

type HomeNode = {
  id: string;
  top: {
    title: string[];
    image: { childImageSharp: { fluid: FluidObject } };
  };
  about: {
    title: string[];
    description: string[];
    link: {
      title: string;
    };
    image: { childImageSharp: { fluid: FluidObject } };
  };
  services: {
    link: {
      title: string;
    };
    list: {
      title: string[];
      description: string[];
      image: { childImageSharp: { fluid: FluidObject } };
    }[];
  };
};

type Props = {
  location: Location;
  data: {
    home: {
      edges: [{ node: HomeNode }];
    };
  };
};

function Home({ location, data: { home } }: Props) {
  return (
    <Layout
      lang={defaultLangKey}
      location={location}
      pathnameWithoutLang={models.pages!.home.path}
    >
      <SEO
        featuredImage={seoData.home.featuredImage?.[defaultLangKey]}
        title={seoData.home.title[defaultLangKey]}
        description={seoData.home.description[defaultLangKey]}
        lang={defaultLangKey}
        pathname={location.pathname}
      />
      {home.edges.map(({ node }: { node: HomeNode }) => (
        <div key={node.id}>
          <section
            css={tw`flex flex-col justify-center -mt-12 md:-mt-24 relative`}
            style={{ height: '100vh', top: '-72px', zIndex: -1 }}
          >
            <BackgroundImage
              Tag="div"
              fluid={node.top.image.childImageSharp.fluid}
              fadeIn={true}
              css={tw`w-full h-full flex-none bg-cover text-center bg-gray-200 mx-auto`}
              style={{ position: 'absolute', zIndex: -1 }}
            />
            <div
              css={tw`w-full h-full flex-none bg-cover text-center bg-gray-900 bg-opacity-50 mx-auto`}
              style={{ position: 'absolute', zIndex: -1 }}
            />
            <div css={tw`flex flex-col items-center w-11/12 md:w-full mx-auto`}>
              <div css={tw`w-8/12 mx-auto md:w-full`}>
                <LogoLg color={'#fff'} />
              </div>
              <div css={tw`mt-12`}>
                <h1
                  css={tw`text-white text-base md:text-xl font-bold text-center leading-loose`}
                >
                  {node.top.title.map((line) => (
                    <span key={line}>
                      {line}
                      <br />
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </section>
          <ContentWidth>
            <section>
              <div css={tw`flex flex-col mb-24 md:mb-48`}>
                <h2
                  css={tw`mb-8 md:mt-24 md:mb-12 text-title font-bold text-3xl md:text-4xl`}
                >
                  about
                </h2>
                <div
                  css={tw`flex flex-col items-center justify-center leading-none`}
                >
                  <div css={tw`text-3xl md:text-5xl leading-normal font-bold`}>
                    {node.about.title.map((line) => {
                      return (
                        <p
                          css={tw`odd:-ml-12 even:ml-12 md:odd:-ml-24 md:even:ml-24`}
                          key={line}
                        >
                          {line}
                        </p>
                      );
                    })}
                  </div>
                  <div
                    css={tw`text-center text-base md:text-2xl font-bold leading-loose my-16 md:my-24`}
                  >
                    {node.about.description.map((line) => {
                      return <p key={line}>{line}</p>;
                    })}
                  </div>
                  <div css={tw`text-lg md:text-3xl font-bold`}>
                    <p>
                      <Link
                        css={tw`hover:opacity-50 border-black border-b-2 pb-2`}
                        to={models.pages.about.path}
                      >
                        {node.about.link.title}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <Image fluid={node.about.image.childImageSharp.fluid} />
              </div>
            </section>
            <section css={tw`mt-24`}>
              <div css={tw`flex flex-col`}>
                <h2
                  css={tw`mb-8 md:mb-12 text-title font-bold text-3xl md:text-4xl`}
                >
                  services
                </h2>
              </div>
              <div>
                {node.services.list.map((service, index) => {
                  const isEven = (index + 1) % 2 === 0;
                  return (
                    <div
                      key={`service-${index}`}
                      css={[
                        tw`md:flex mb-16 md:mb-24 last:mb-0`,
                        isEven ? tw`md:flex-row-reverse` : tw``,
                      ]}
                    >
                      <div
                        css={tw`w-full md:w-2/5 relative pb-9/16 md:pb-1/4`}
                        style={{ height: 0 }}
                      >
                        <Image
                          fluid={service.image.childImageSharp.fluid}
                          css={tw`absolute top-0 left-0 right-0 bottom-0 h-full`}
                          style={{ position: 'absolute' }}
                        />
                      </div>
                      <div
                        css={[
                          tw`md:flex-grow`,
                          isEven ? tw`md:text-right` : null,
                        ]}
                      >
                        <div
                          css={tw`my-6 md:my-0 relative text-2xl md:text-3xl lg:text-4xl font-bold leading-normal`}
                        >
                          <span
                            css={[
                              tw`hidden md:block absolute top-half bg-black w-32`,
                              isEven ? tw`-right-20` : tw`-left-20`,
                            ]}
                            style={{
                              height: '2px',
                              translate: 'transformY(-50%)',
                            }}
                          />
                          <div
                            css={[
                              tw`mx-0`,
                              isEven ? tw`md:mr-16` : tw`md:ml-16`,
                            ]}
                          >
                            {service.title.map((line) => (
                              <p key={line}>{line}</p>
                            ))}
                          </div>
                        </div>
                        <div
                          css={[
                            tw`mx-0 mt-6`,
                            isEven ? tw`md:mr-24` : tw`md:ml-24`,
                          ]}
                        >
                          <div
                            css={[
                              tw`ml-2 md:ml-0 pl-4 border-l-4 md:py-3 border-black text-base md:text-xl font-bold`,
                              isEven
                                ? tw`md:pr-6 md:border-r-4 md:pl-0 md:border-l-0`
                                : tw`md:pl-6 md:border-l-4`,
                            ]}
                          >
                            {service.description.map((line) => (
                              <p key={line}>{line}</p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                css={tw`text-lg md:text-3xl font-bold text-center mt-12 md:mt-24`}
              >
                <p>
                  <Link
                    css={tw`hover:opacity-50 border-black border-b-2 pb-2`}
                    to={models.pages.services.path}
                  >
                    {node.services.link.title}
                  </Link>
                </p>
              </div>
            </section>
          </ContentWidth>
        </div>
      ))}
    </Layout>
  );
}

export const homeQuery = graphql`
  query HomeQuery {
    home: allHomeJson {
      edges {
        node {
          id
          top {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          about {
            title
            description
            link {
              title
            }
            image {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          services {
            list {
              title
              description
              image {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            link {
              title
            }
          }
        }
      }
    }
  }
`;

export default Home;
