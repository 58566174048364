type SEOData = {
  [page: string]: {
    title: {
      [lang: string]: string;
    };
    description: {
      [lang: string]: string;
    };
    featuredImage?: {
      [lang: string]: string;
    };
  };
};

export const seoData: SEOData = {
  posts: {
    title: {
      ja: 'Blog',
    },
    description: {
      ja: 'アメリズムのBlog一覧。留学のアレコレについて投稿しています。',
    },
    featuredImage: {
      ja: require('../images/ogp-blog.png'),
    },
  },
  home: {
    title: {
      ja: '',
    },
    description: {
      ja: '「自由の国へ」、「自由な留学」を。',
    },
    featuredImage: {
      ja: require('../images/ogp-home.png'),
    },
  },
  about: {
    title: {
      ja: 'About / アメリズムについて',
    },
    description: {
      ja:
        '全てのアメリカ留学を志す方々に、私なりのちょっとした『ism(流儀)』を届けたい。そういった思いを込めて「アメリズム」と名付けました。あなたの「ちょっと尋ねたい」をサポートできるよう一人ひとりにあった提案をさせていただきます。',
    },
    featuredImage: {
      ja: require('../images/ogp-about.png'),
    },
  },
  services: {
    title: {
      ja: 'Services / サポート内容',
    },
    description: {
      ja:
        'あなたに合わせて、細分化された料金プランから提案させていただいております。面談による学校の選定、エッセイサポート、TOEFL iBT 対策など様々なプランをご用意していますので、ぜひご検討ください。',
    },
    featuredImage: {
      ja: require('../images/ogp-services.png'),
    },
  },
  flow: {
    title: {
      ja: 'Flow / 留学までの流れ',
    },
    description: {
      ja:
        '留学までの流れをご紹介します。まずはカウンセリングの予約をしましょう。これが留学までの最初の一歩です。このWebサイトからも予約することが出来ます。メールや電話でも受け付けています。',
    },
    featuredImage: {
      ja: require('../images/ogp-flow.png'),
    },
  },
  qa: {
    title: {
      ja: 'Q&A / よくあるお問い合わせ',
    },
    description: {
      ja: 'よくあるお問い合わせを掲載しております。',
    },
    featuredImage: {
      ja: require('../images/ogp-qa.png'),
    },
  },
  contact: {
    title: {
      ja: 'Contact / お問い合わせ',
    },
    description: {
      ja:
        '留学に関するお問い合わせや、カウンセリングの予約等のお問い合わせはこちらのフォームからお願いいたします。',
    },
    featuredImage: {
      ja: require('../images/ogp-contact.png'),
    },
  },
  privacyPolicy: {
    title: {
      ja: 'Privacy Policy / プライバシーポリシー',
    },
    description: {
      ja: 'プライバシーポリシー',
    },
    featuredImage: {
      ja: require('../images/ogp-privacy-policy.png'),
    },
  },
  notFound: {
    title: {
      ja: '404 / お探しのページは見つかりませんでした',
    },
    description: {
      ja: 'お探しのページは見つかりませんでした。',
    },
    featuredImage: {
      ja: require('../images/ogp-not-found.png'),
    },
  },
};
